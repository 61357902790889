/**
 * Created by gschulz on 17.06.16.
 */
let EfbHelperCoordinateField = function () {

    let _private = {
        wrap:           $(),
        scheme:         '',
        name:           '',
        directions:     [],
        decimal:        null,
        knownSchemes:   ['hour_minute_second_decimal', 'hour_minute_decimal', 'decimal_degree'],
        toStringFormat: {
            hour_minute_second_decimal: '%s%d° %d\' %01.2f\'\'',
            hour_minute_decimal:        '%s%d° %01.3f\'',
            decimal_degree:             '%01.5f'
        }
    };

    this.init = function (wrap) {
        _private.scheme = wrap.data('coordinate-scheme');
        _private.name   = wrap.data('coordinate-name');
        if (!wrap.hasClass('coordinate-wrap') || $.inArray(_private.scheme, _private.knownSchemes) === -1) {
            return false;
        }
        _private.wrap = wrap;
        _private.setDirections();
        _private.bindEvents();
        _private.wrap.data('EfbHelperCoordinateField', this);
        return this;
    };

    this.decimalToScheme = (decimal, type, scheme) => {
        if (type === 'lat') {
            _private.directions = ['N', 'S'];
        } else {
            _private.directions = ['E', 'W'];
        }
        scheme  = scheme || coordinateScheme;
        let res = _private.decimalToScheme(decimal, scheme);
        return _private.toString(res);
    };

    _private.setDirections = () => {
        if (_private.scheme === 'decimal_degree') {
            return;
        }
        _private.wrap.find('select').find('option').each((i, option) => {
            _private.directions.push($(option).val())
        });
    };

    _private.calculateCoordinate = () => {
        if (_private.wrap.find('div.formelement.error').length) {
            _private.wrap.find('input[type=hidden]').pVal(null).val('')
            this.decimalCoordinateUpdated(null);
            return;
        }
        let values = {
            scheme: _private.scheme,
            before: _private.wrap.find('input[type=hidden]').val()
        };
        let result = 0;
        _private.wrap.find('input[type=text],select').each((i, input) => {
            let name  = $(input).attr('name');
            let value = $(input).val();
            if (!$(input).is('select')) {
                value = parseFloat(value);
            }
            name         = name.replace(']', '').split('[');
            name         = name[name.length - 1];
            values[name] = value;
        });

        switch (values.scheme) {
            case 'hour_minute_second_decimal':
                result = values['degree'] + (values['min'] / 60) + (values['sec'] / 3600);
                break;
            case 'hour_minute_decimal':
                result = values['degree'] + (values['min'] / 60);
                break;
            case 'decimal_degree':
                result = values['decimal'];
                break;
        }
        values.result = result;
        if ($.inArray(values['direction'], ['S', 'W']) !== -1) {
            values.result *= -1;
        }
        _private.decimal = values.result;
        _private.wrap.find('input[type=hidden]').val(values.result).trigger('keyup').trigger('blur');
    };

    _private.bindEvents = () => {
        _private.wrap.find('input[type=text]')
                .unbind('keyup.calculateCoordinate').on('keyup.calculateCoordinate'
            , e => {
                Validator.validateElement($(e.target), false);
                _private.calculateCoordinate();
                this.decimalCoordinateChanged(_private.decimal);
            }
        );
        _private.wrap.find('input[type=text]')
                .unbind('blurEnter.calculateCoordinate').on('blurEnter.calculateCoordinate'
            , e => {
                Validator.validateElement($(e.target), false);
                _private.calculateCoordinate();
                this.decimalCoordinateUpdated(_private.decimal);
            }
        );
        _private.wrap.find('select')
                .unbind('change.calculateCoordinate').on('change.calculateCoordinate'
            , e => {
                Validator.validateElement($(e.target), false);
                _private.calculateCoordinate();
                this.decimalCoordinateUpdated(_private.decimal);
            }
        );
    };

    _private.decimalToScheme = (decimal, scheme) => {
        scheme     = scheme || _private.scheme;
        let result = {decimal: decimal, scheme: scheme};
        switch (scheme) {
            case 'hour_minute_second_decimal':
                result.direction = decimal < 0 ? _private.directions[1] : _private.directions[0];
                decimal          = Math.abs(decimal);
                result.degree    = parseInt(decimal);
                result.min       = parseInt(((decimal - result.degree) * 60).toString());
                result.sec       = EfbHelper.round(((decimal - result.degree - result.min / 60) * 3600), 4);
                result.strArgs   = [result.direction, result.degree, result.min, result.sec];
                break;
            case 'hour_minute_decimal':
                result.direction = decimal < 0 ? _private.directions[1] : _private.directions[0];
                decimal          = Math.abs(decimal);
                result.degree    = parseInt(decimal);
                result.min       = EfbHelper.round((decimal - result.degree) * 60, 4);
                result.strArgs   = [result.direction, result.degree, result.min];
                break;
            case 'decimal_degree':
                result.decimal = decimal;
                result.strArgs = [result.decimal];
                break;
            default:
                break;
        }
        return result;
    };

    _private.toString = (result) => {
        return jQuery.vsprintf(_private.toStringFormat[result.scheme], result.strArgs);
    };

    _private.schemePartsToDom = (schemeParts, triggerEvents = true) => {
        $.each(schemeParts, (key, value) => {
            let element = _private.wrap.find('[name*="[' + key + ']"]');
            element.val(value);
            if(!triggerEvents){
                return;
            }
            if (element.is('input')) {
                element.keyup();
            } else {
                element.change();
            }

        });
        let element = _private.wrap.find('[name="' + _private.name + '"]');
        element.val(schemeParts.decimal).keyup();
        Validator.validateElement(element);
    };

    this.setDecimalValue = (decimal) => {
        _private.schemePartsToDom(_private.decimalToScheme(decimal), false);
        return this;
    };

    this.getDecimalValue = () => {
        if (_.isNull(_private.decimal)) {
            _private.calculateCoordinate();
        }
        return _private.decimal;
    };

    this.decimalCoordinateUpdated = () => {};
    this.decimalCoordinateChanged = () => {};
};